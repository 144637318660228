import i18n from '@/plugins/i18n'
export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("model.prop.name"),
          value: 'prizeName',
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("model.prop.quantity"),
          value: 'quantity',
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("model.name.rewards"),
          value: 'prizeRewards',
          sortable: false,
          filterable: false,
        },
      ],
    },
  },
  read: false,
  add: {
    fields: [{
        type: 'model',
        key: 'eventId',
        name: 'Event',
        model: 'event',
        optionText: 'name',
        optionValue: 'id',
        defaultValue: 1,
      },
      {
        type: 'model',
        key: 'gamePrizeId',
        name: 'Game Prize',
        model: 'gamePrize',
        optionText: 'name',
        optionValue: 'id',
        defaultValue: 1,
      },
      {
        type: 'number',
        key: 'quantity',
        name: 'Quantity',
      },
    ],
    rules: {
      schoolId: [v => !!v || 'School is required'],
      name: [v => !!v || 'Name is required'],
    },
  },
  edit: true,
  delete: true,
}
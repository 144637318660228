export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'School',
          value: 'School',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Classroom',
          value: 'Classroom',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Student',
          value: 'Student',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Event',
          value: 'Event',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Chapter',
          value: 'Chapter',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Question',
          value: 'Question',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Questions',
          value: 'countQuestion',
        },
        {
          text: 'Corrects',
          value: 'countCorrect',
        },
        {
          text: 'Accuracy',
          value: 'submissionAccuracy',
        },
        {
          text: 'Stars',
          value: 'submissionStar',
        },
        {
          text: 'Score',
          value: 'score',
        },
        {
          text: 'Time',
          value: 'time',
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:false,
  edit:false,
  delete:false,
}
export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Created',
          value: 'dateCreated',
          filterable: false,
        },
        {
          text: 'Student',
          value: 'Student',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Type ID',
          value: 'typeId',
        },
        {
          text: 'Version',
          value: 'version',
        },
        {
          text: 'Build',
          value: 'build',
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Student',
          value: 'Student',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Game',
          value: 'Game',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Event',
          value: 'Event',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Version',
          value: 'version',
        },
        {
          text: 'Data',
          value: 'data',
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:false,
  edit:false,
  delete:false,
}
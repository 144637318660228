import Moderator from '@/models/bread/Moderator/eventOnFestival'
export default {
  key: 'EventOnFestival',
  name: {
    singular: 'Event',
    plural: 'Events',
  },
  parents: [
    'Event',
    'Festival',
  ],
  Admin: false,
  Moderator: Moderator,
  Teacher: false,
}
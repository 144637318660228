import Moderator from '@/models/bread/Moderator/festivalSponsor'

export default {
  key: 'FestivalSponsor',
  name: {
    singular: 'Sponsor',
    plural: 'Sponsors',
  },
  parents: [
    'Festival',
  ],
  Admin: false,
  Moderator: Moderator,
  Teacher: false,
}
export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Image',
          value: 'imageUrl',
        },
        {
          text: 'Key',
          value: 'key',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Name',
          value: 'translatableName',
        },
        {
          text: 'Sort',
          value: 'sort',
        },
        {
          text: 'Rewards',
          value: 'countPrizeTypeReward',
        },
        {
          text: 'Published',
          value: 'isPublished',
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Name',
          value: 'translatableName',
        },
        {
          text: 'Published',
          value: 'isPublished',
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:false,
  edit:{
    fields:[
      {
        type:'boolean',
        key:'isPublished',
        name:'Published',
      },
      {
        type:'number',
        key:'sort',
        name:'Sort',
      },
    ],
    rules:{
      //
    },
  },
  delete:false,
}

import Moderator from '@/models/bread/Moderator/eventPlayer'


export default {
    key: 'EventPlayer',
    name: {
        singular: 'Player',
        plural: 'Players',
    },
    parents: [
        'Event',
    ],
    Admin: false,
    Moderator: Moderator,
    Teacher: false,
}
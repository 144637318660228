import i18n from '@/plugins/i18n'
export default {
  browse: {
    table: {
      headers: [{
          text:i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("model.name.chapter"),
          value: 'chapterKey',

        },
        {
          text: i18n.t("model.name.question"),
          value: 'questionKey',
        },
      ],
    },
  },
  read: false,
  add: {
    fields: [{
        type: 'model',
        key: 'chapterKey',
        name: 'Chapter',
        model: 'chapter',
        optionText: 'name',
        defaultValue: 1,
      },
      {
        type: 'model',
        key: 'questionKey',
        name: 'Question',
        model: 'question',
        optionText: 'name',
        optionValue: 'key',
      },
    ],
    rules: {
      chapterId: [v => !!v || 'Chapter is required'],
      questionKey: [v => !!v || 'Question is required'],
    },
  },
  edit: false,
  delete: true,
}
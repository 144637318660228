export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Shop',
          value: 'Shop',
        },
        {
          text: 'Item',
          value: 'Item',
        },
      ],
    },
  },
  read:false,
  add:false,
  edit:false,
  delete:false,
}
import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  i18n.t("model.name.sponsor"),
          value: 'Sponsor',
        },
        {
          text:  'Type',
          value: 'type',
        },
        {
          text:  'Amount',
          value: 'amount',
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key: 'Info',
        label:i18n.t("string.info"),
      },
    ],
    table:{
      headers: [
        {
          text:  i18n.t("model.name.sponsor"),
          value: 'Sponsor',
        },
        {
          text:  'Type',
          value: 'type',
        },
        {
          text:  'Amount',
          value: 'amount',
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'model',
        key:'sponsorId',
        name:i18n.t("model.name.sponsor"),
        model:'sponsor',
        optionValue: 'id',
        defaultValue:1,
      },
      {
        type:'number',
        key:'amount',
        name:'Amount',
      },
      {
        type:'string',
        key:'type',
        name:'Type',
      },
     
    ],
    rules:{
      amount: [v => !!v || 'Amount is required'],
      type: [v => !!v || 'Type is required'],
    },
  },
  edit:true,
  delete:true,
}
import Moderator from '@/models/bread/Moderator/job'

export default {
  key:'Job',
  server:'job',
  name:{
    singular:'Job',
    plural:'Jobs',
  },
  parents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
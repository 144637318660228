import Moderator from '@/models/bread/Moderator/theme'

export default {
  key:'Theme',
  server:'game',
  name:{
    singular:'Theme',
    plural:'Themes',
  },
  parents:[],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
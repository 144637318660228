export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Event',
          value: 'Event',
          sortable: false,
          filterable: false,
        },
        {
          text: 'GamePrize',
          value: 'GamePrize',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Participant',
          value: 'Participant',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Claimed',
          value: 'timestampClaimed',
          filterable: false,
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:false,
  edit:false,
  delete:true,
}
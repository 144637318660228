import i18n from "@/plugins/i18n";

export default {
  browse: {
    table: {
      headers: [
        {
          text: "Institution Name",
          value: "institutionName",
        },
        {
          text: "Principal Name",
          value: "principalName",
        },
        {
          text: "Applicant Name",
          value: "applicantName",
        },
        {
          text: i18n.t("model.prop.status"),
          value: "applicationStatus",
          filterable: false,
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'Info',
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: "Institution Name",
          value: "institutionName",
        },
        {
          text: "Institution Town",
          value: "institutionTown",
        },
        {
          text: "Principal Name",
          value: "principalName",
        },
        {
          text: "Applicant Name",
          value: "applicantName",
        },
        {
          text: "Applicant Position",
          value: "applicantPosition",
        },
        {
          text: "Applicant Mobile",
          value: "applicantMobile",
        },
        {
          text: "Applicant Email",
          value: "applicantEmail",
        },
        {
          text: i18n.t("model.prop.status"),
          value: "applicationStatus",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add: false,
  edit: false,
  delete: true,
};

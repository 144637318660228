import Moderator from '@/models/bread/Moderator/galleryAnalysis'

export default {
  key:'GalleryAnalysis',
  name:{
    singular:'GalleryAnalysis',
    plural:'GalleryAnalysis',
  },
  parents:[],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
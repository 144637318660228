import Admin from '@/models/bread/Admin/log'
import Moderator from '@/models/bread/Moderator/log'

export default {
  key:'Log',
  name:{
    singular:'Log',
    plural:'Logs',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:Moderator,
  Teacher:false,
}
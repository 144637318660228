import Admin from '@/models/bread/Admin/school'
import Moderator from '@/models/bread/Moderator/school'
import Teacher from '@/models/bread/Teacher/school'

export default {
  key:'School',
  name:{
    singular:'School',
    plural:'Schools',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:Moderator,
  Teacher:Teacher,
}

import Moderator from '@/models/bread/Moderator/galleryAwardDrawing'


export default {
  key:'GalleryAwardDrawing',
  name:{
    singular:'GalleryAwardDrawing',
    plural:'GalleryAwardDrawing',
  },
  parents:[
    'Theme',
  ],
  grandParents:[],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
export default [
  {
    name:'Locked',
    value:'locked',
  },
  {
    name:'Open',
    value:'open',
  },
  {
    name:'Constructing',
    value:'constructing',
  },
  {
    name:'Closed',
    value:'closed',
  },
  {
    name:'Renovating',
    value:'renovating',
  },
]
import Moderator from '@/models/bread/Moderator/festivalPrize'

export default {
  key: 'FestivalPrize',
  name: {
    singular: 'Prize',
    plural: 'Prizes',
  },
  parents: [
    'Festival',
  ],
  Admin: false,
  Moderator: Moderator,
  Teacher: false,
}
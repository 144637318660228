import Moderator from '@/models/bread/Moderator/festivalSchool'

export default {
  key: 'FestivalSchool',
  name: {
    singular: 'School',
    plural: 'Schools',
  },
  parents: [
    'Festival',
    'School',
  ],
  Admin: false,
  Moderator: Moderator,
  Teacher: false,
}
import Admin from '@/models/bread/Admin/question'

export default {
  key:'Question',
  name:{
    singular:'Question',
    plural:'Questions',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
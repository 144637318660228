import i18n from '@/plugins/i18n'
export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
        },
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.prop.code"),
          value: 'code',
        },
        {
          text: i18n.t("model.name.teachers"),
          value: 'countTeacher',
        },
        {
          text: i18n.t("model.name.classrooms"),
          value: 'countClassroom',
        },
        {
          text: i18n.t("model.name.events"),
          value: 'countEvent',
        },
        {
          text: '',
          value: 'schoolSelect',
        },
      ],
    },
  },
  read: {
    tabs: [{
      key: 'Info',
      label: i18n.t("string.info"),
    }, ],
    table: {
      headers: [{
          text: i18n.t("model.prop.logo"),
          value: 'logoUrl',
        },
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.prop.code"),
          value: 'code',
        },
        {
          text: i18n.t("view.PageBackendSchool.invitation"),
          value: 'schoolMessageInvitation',
        },
        {
          text: i18n.t("model.prop.status"),
          value: 'isActive',
        },
        {
          text: i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [{
      type: 'string',
      key: 'name',
      name: i18n.t("model.prop.name"),
    }, ],
    rules: {
      name: [v => !!v ||  i18n.t("rules.name")],
    },
  },
  edit: {
    fields: [
      {
        type: 'string',
        key: 'name',
        name: 'Name',
      }, 
      {
        type: 'string',
        key: 'code',
        name: 'Code',
      },
    ],
    rules: {
      name: [v => !!v || 'Name is required'],
      code: [v => !!v || 'Code is required'],
    },
  },
  delete: true,
}
import Admin from '@/models/bread/Admin/item'

export default {
  key:'Item',
  name:{
    singular:'Item',
    plural:'Items',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
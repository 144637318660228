import Moderator from '@/models/bread/Moderator/festival'

export default {
    key: 'Festival',
    name: {
        singular: 'Festival',
        plural: 'Festivals',
    },
    Admin: false,
    Moderator: Moderator,
    Teacher: false,
}
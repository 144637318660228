import Moderator from '@/models/bread/Moderator/player'

export default {
  key:'Player',
  name:{
    singular:'Player',
    plural:'Players',
  },
  parents:[],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Event',
          value: 'Event',
          sortable: false,
          filterable: false,
        },
        {
          text: 'GamePrize',
          value: 'GamePrize',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Quantity',
          value: 'quantity',
          sortable: false,
          filterable: false,
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'model',
        key:'eventId',
        name:'Event',
        model:'event',
        optionText:'name',
        optionValue:'id',
        defaultValue:1,
      },
      {
        type:'model',
        key:'gamePrizeId',
        name:'Game Prize',
        model:'gamePrize',
        optionText:'name',
        optionValue:'id',
        defaultValue:1,
      },
      {
        type:'number',
        key:'quantity',
        name:'Quantity',
      },
    ],
    rules:{
      schoolId: [v => !!v || 'School is required'],
      name: [v => !!v || 'Name is required'],
    },
  },
  edit:true,
  delete:true,
}
export default {
  browse: {
    table: {
      headers: [{
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Classroom',
          value: 'ClassroomName',
        },
        {
          text: 'Student',
          value: 'Student',
        },
        {
          text: 'Key',
          value: 'Student.code',
          sortable: false,
          filterable: false,
        },
      ],
    },
  },
  read: false,
  add: false,
  edit: false,
  delete: false,
}
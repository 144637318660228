
import Moderator from '@/models/bread/Moderator/eventSponsor'


export default {
    key: 'EventSponsor',
    name: {
        singular: 'Sponsor',
        plural: 'Sponsors',
    },
    parents: [
        'Event',
    ],
    Admin: false,
    Moderator: Moderator,
    Teacher: false,
}
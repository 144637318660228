import Moderator from '@/models/bread/Moderator/institutionEvent'

export default {
  key:'Event',
  name:{
    singular:'Event',
    plural:'Events',
  },
  parents:[
    'Institution'
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
import Admin from '@/models/bread/Admin/submission'

export default {
  key:'Submission',
  name:{
    singular:'Submission',
    plural:'Submissions',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'School',
          value: 'School',
          sortable: false,
          filterable: false,
        },
        {
          text: 'UID',
          value: 'name',
        },
        {
          text: 'Full Name',
          value: 'fullname',
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key:'Student',
        label:'students',
      },
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'School',
          value: 'School',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:false,
  edit:false,
  delete:false,
}
import datasetBuildingStatus from '@/datasets/buildingStatus'
export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Image',
          value: 'buildingImage',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Island',
          value: 'island',
        },
        {
          text: 'Teacher',
          value: 'isTeacher',
        },
        {
          text: 'Status',
          value: 'status',
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Name',
          value: 'translatableName',
        },
        {
          text: 'Island',
          value: 'island',
        },
        {
          text: 'Teacher',
          value: 'isTeacher',
        },
        {
          text: 'Route',
          value: 'route',
        },
        {
          text: 'Icon',
          value: 'icon',
        },
        {
          text: 'Color',
          value: 'color',
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:false,
  edit:{
    fields:[
      {
        type:'select',
        key:'status',
        name:'Status',
        options:datasetBuildingStatus,
        optionText:'name',
        optionValue:'value',
        defaultValue:'locked',
      },
      {
        type:'boolean',
        key:'isTeacher',
        name:'Teacher',
      },
      {
        type:'string',
        key:'sort',
        name:'Sort',
      },
    ],
    rules:{
      sort: [v => !!v || 'Sort is required'],
    },
  },
  delete:false,
}
export default {
  browse: {
    table: {
      headers: [{
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Classroom',
          value: 'Classroom',
        },
        {
          text: 'Event',
          value: 'Event',
        },
        {

          value: 'delete',
          sortable: false,
          filterable: false,
        },
      ],
    },
  },

  add: {
    fields: [
      {
        type: 'model',
        key: 'classroomId',
        name: 'Classroom',
        model: 'classroom',
      },
      {
        type: 'model',
        key: 'eventId',
        name: 'Event',
        model: 'event',
      },
    ],
    rules: {
      classroomId: [v => !!v || 'Classroom is required'],
      eventId: [v => !!v || 'Event is required'],
    },
  },
  edit: false,
  delete: true,
  read: false,
}
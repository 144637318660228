import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [{
        text: i18n.t("model.prop.no"),
        value: 'index',
        sortable: false,
      },
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.prop.status"),
          value: 'jobStatus',
        },
        {
          text: 'Schedule Time',
          value: 'timestampScheduled',
          filterable: false,
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'Info',
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.prop.data"),
          value: 'data',
        },
        {
          text: i18n.t("model.prop.status"),
          value: 'jobStatus',
        },
        {
          text: 'Schedule Time',
          value: 'timestampScheduled',
          filterable: false,
        },
        {
          text: i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: true,
  edit: true,
  delete: true,
}
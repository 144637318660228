
import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Name',
          value: 'Sponsor',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
      ],
    },
  },
  read: {
    tabs: [

      {
        key: 'Info',
        label:  i18n.t("string.info"),
      },

    ],
    table: {
      headers: [
        {
          text: 'Name',
          value: 'Sponsor',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
        {
          text: 'Type',
          value: 'SponsorType.name',
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type:'model',
        key:'sponsorId',
        server:'event',
        name:i18n.t("model.name.sponsor"),
        model:'sponsor',
        optionValue: 'id',
        defaultValue:1,
      },
      {
        type:'model',
        key:'sponsorTypeId',
        server:'event',
        name:'Type',
        model:'sponsorType',
        optionValue: 'id',
        defaultValue:1,
      },
      {
        type:'number',
        key:'amount',
        name:'Amount',
      },
    ],
    rules: {
      amount: [v => !!v || 'Amount is required'],
      sponsorId: [v => !!v || 'Sponsor is required'],
    },
  },
  edit: true,
  delete: true,
}
import i18n from '@/plugins/i18n'
export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },

        {
          text: i18n.t("model.name.classroom"),
          value: 'Classroom',
        },
        {
          text: i18n.t("model.name.school"),
          value: 'Classroom.School.name',
        },
        {

          value: 'delete',
          sortable: false,
          filterable: false,
        },
      ],
    },
  },

  add: {
    fields: [{
      type: 'model',
      key: 'classroomId',
      name: i18n.t("model.name.classroom"),
      model: 'classroom',

    }, ],
    rules: {
      classroomId: [v => !!v || i18n.t("rules.classroom"),],
    },
  },
  edit: false,
  delete: true,
  read: false,
}
import Admin from '@/models/bread/Admin/questionSet'

export default {
  key:'Question Set',
  name:{
    singular:'Question Set',
    plural:'Question Sets',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
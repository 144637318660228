import Admin from '@/models/bread/Admin/shop'

export default {
  key:'Shops',
  name:{
    singular:'Shop',
    plural:'Shops',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
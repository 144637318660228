import Moderator from '@/models/bread/Moderator/accountClassroom'

export default {
  key:'Classroom',
  name:{
    singular:'Classroom',
    plural:'Classrooms',
  },
  parents:[
    'Account',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Achievement',
          value: 'Achievement',
        },
        {
          text: 'Student',
          value: 'Student',
        },
        {
          text: 'Participant',
          value: 'Participant',
        },
        {
          text: 'Current',
          value: 'current',
        },
        {
          text: 'Target',
          value: 'target',
        },
        {
          text: 'Progress',
          value: 'achievementProgressProgress',
        },
        {
          text: 'Completed',
          value: 'achievementProgressTimestampCompleted',
        },
        {
          text: 'Claimed',
          value: 'achievementProgressTimestampClaimed',
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:false,
  edit:false,
  delete:true,
}
import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [{
        text: i18n.t("model.prop.no"),
        value: 'index',
        sortable: false,
      },
        {
          text: i18n.t("model.prop.name"),
          value: 'nameWithUrl',
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'Info',
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.logo"),
          value: 'logoUrl',
        },
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.prop.websiteUrl"),
          value: 'websiteUrl',
        },
        {
          text: i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: 'string',
        key: 'name',
        name: i18n.t("model.prop.name"),
      },
      {
        type: 'string',
        key: 'websiteUrl',
        name: i18n.t("model.prop.websiteUrl"),
      },
    ],
    rules: {
      name: [v => !!v || i18n.t("rules.name")],
    },
  },
  edit: true,
  delete: true,
}
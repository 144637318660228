import Admin from '@/models/bread/Admin/participant'
import Moderator from '@/models/bread/Moderator/participant'

export default {
  key: 'Participant',
  name: {
    singular: 'Participant',
    plural: 'Participants',
  },
  parents: [
    //
  ],
  Admin: Admin,
  Moderator: Moderator,
  Teacher: false,
}

import Moderator from '@/models/bread/Moderator/eventRule'


export default {
    key: 'EventRule',
    name: {
        singular: 'Rule',
        plural: 'Rules',
    },
    parents: [
        'Event',
    ],
    Admin: false,
    Moderator: Moderator,
    Teacher: false,
}
import Admin from '@/models/bread/Admin/record'

export default {
  key:'Record',
  name:{
    singular:'Record',
    plural:'Records',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
import Admin from '@/models/bread/Admin/building'

export default {
  key:'Building',
  name:{
    singular:'Building',
    plural:'Buildings',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Module',
          value: 'Module',
        },
        {
          text: 'Set',
          value: 'setKey',
        },
        {
          text: 'Item',
          value: 'Item',
        },
        {
          text: 'Chance',
          value: 'chance',
        },
        {
          text: 'Quantity',
          value: 'quantity',
        },
        {
          text: 'Min',
          value: 'min',
        },
        {
          text: 'Max',
          value: 'max',
        },
      ],
    },
  },
  read:false,
  add:false,
  edit:false,
  delete:false,
}
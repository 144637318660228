export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Account',
          value: 'Account',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Model',
          value: 'model',
        },
        {
          text: 'Model ID',
          value: 'modelId',
        },
        {
          text: 'Action',
          value: 'action',
        },
        {
          text:'Data',
          value:'data-table-expand',
        },
      ],
    },
  },
  read:false,
  add:false,
  edit:false,
  delete:false,
}
import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: "Sort",
          value: 'sort',
          sortable: false,
          filterable: false,
        },
        {
          text:  'Prize',
          value: 'festivalPrizeName',
        },
        {
          text:  'Item',
          value: 'festivalItemName',
        },
        {
          text:  'Image',
          value: 'prizeImage',
        },
        {
          text:  'Amount',
          value: 'amount',
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key: 'Info',
        label:i18n.t("string.info"),
      },
    ],
    table:{
      headers: [
        {
          text: 'Image',
          value: 'imageUrl',
        },
        {
          text:  'Sort',
          value: 'sort',
        },
        {
          text:  'Prize',
          value: 'festivalPrizeName',
        },
        {
          text:  'Item',
          value: 'festivalItemName',
        },
        {
          text:  'Amount',
          value: 'amount',
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'string',
        key:'prizeName',
        name:'Prize Name',
      },
      {
        type:'string',
        key:'itemName',
        name:'Item Name',
      },
      {
        type:'number',
        key:'amount',
        name:'Amount',
      },
      {
        type:'number',
        key:'sort',
        name:'Sort',
        min:1,
      },
     
    ],
    rules:{
      prizeName: [v => !!v || 'Prize name is required'],
      itemName: [v => !!v || 'Item name is required'],
      sort: [v => !!v || 'Sort is required'],
    },
  },
  edit:true,
  delete:true,
}
import i18n from "@/plugins/i18n";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.start"),
          value: 'timestampStart',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.end"),
          value: 'timestampEnd',
          filterable: false,
        },
        {
          text: "Title",
          value: "translatableTitle",
        },
        {
          text: "Image",
          value: "drawing",
        },
        {
          text: "Action",
          value: "translatableAction",
        },
        {
          text: 'Clicked',
          value: 'count',
        },
        {
          text: i18n.t("model.prop.status"),
          value: 'eventStatus',
          filterable: false,
        },
        {
          text: 'Published',
          value: 'isPublished',
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
     
    ],
    table: {
      headers: [
        {
          text: 'Image',
          value: 'imageUrl',
        },
        {
          text: 'Priority',
          value: 'priority',
        },
        {
          text: "Title",
          value: "translatableTitle",
        },
        {
          text: "Body",
          value: "translatableBody",
        },
        {
          text: "Action Url",
          value: "actionUrl",
        },
        {
          text: "Action",
          value: "translatableAction",
        },
        {
          text: 'Published',
          value: 'isPublished',
        },
        {
          text:  i18n.t("model.prop.start"),
          value: 'timestampStart',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.end"),
          value: 'timestampEnd',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.status"),
          value: 'eventStatus',
          filterable: false,
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'string',
        key:'translatableTitle',
        name:'Title',
        defaultValue:'{"en":"Get This!","zh":"得到这个！","ms":"Ambil ini!"}'
      },
      {
        type:'string',
        key:'translatableBody',
        name:'Body',
        defaultValue: '{"en":"Lightning Wizard Set","zh":"闪电精灵套装","ms":"Set Wizard Kilat"}'
      },
      {
        type:'string',
        key:'actionUrl',
        name:'Action Url',
        defaultValue:"/shop",
      },
      {
        type:'string',
        key:'translatableAction',
        name:'Action',
        defaultValue: '{"en":"Shop","zh":"店铺","ms":"kedai"}',
      },
    ],
    rules:{
      translatableTitle: [v => !!v || 'News tile is required'],
    },
  },
  edit:true,
  delete: true,
};

import Admin from '@/models/bread/Admin/classroomOnEvent'
import Moderator from '@/models/bread/Moderator/classroomOnEvent'
import Teacher from '@/models/bread/Teacher/classroomOnEvent'

export default {
  key: 'ClassroomOnEvent',
  name: {
    singular: 'Classroom',
    plural: 'Classrooms',
  },
  parents: [
    'Event',
    'Classroom',
  ],
  Admin: Admin,
  Moderator: Moderator,
  Teacher: Teacher,
}

import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  'Image',
          value: 'itemImage',
        },
        {
          text:  'Item',
          value: 'Item.name',
        },
        {
          text:  'Type',
          value: 'Item.type',
        },
        {
          text:  'Quantity',
          value: 'quantity',
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  read:{
    tabs: [
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  'Image',
          value: 'itemImage',
        },
        {
          text:  'Item',
          value: 'Item.name',
        },
        {
          text:  'Price',
          value: 'itemPrice',
        },
        {
          text:  'Quantity',
          value: 'quantity',
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add:false,
  edit:false,
  delete:true,
}
import Admin from '@/models/bread/Admin/randomItem'

export default {
  key:'Random Items',
  name:{
    singular:'Random Item',
    plural:'Random Items',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
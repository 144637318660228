export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Model',
          value: 'model',
        },
        {
          text: 'Model ID',
          value: 'modelId',
        },
      ],
    },
  },
  read:false,
  add:false,
  edit:false,
  delete:false,
}
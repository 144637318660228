import Moderator from '@/models/bread/Moderator/eventOnClassroom'
import Teacher from '@/models/bread/Teacher/eventOnClassroom'
export default {
  key: 'EventOnClassroom',
  name: {
    singular: 'Event',
    plural: 'Events',
  },
  parents: [
    'Event',
    'Classroom',
  ],
  Admin: false,
  Moderator: Moderator,
  Teacher: Teacher,
}
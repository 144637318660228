import Moderator from '@/models/bread/Moderator/inventory'

export default {
  key:'Inventory',
  name:{
    singular:'Inventory',
    plural:'Inventories',
  },
  parents:[
    'Item',
    'Student',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
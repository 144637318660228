
import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text:  "Avatar",
          value: 'avatar',
        },
        {
          text: "Mochi Id",
          value: "playerMochiId",
        },
        // {
        //   text:  "PIN",
        //   value: 'PIN',
        // },
        {
          text:  i18n.t("model.prop.status"),
          value: 'isActive',
        },
      ],
    },
  },
  read:{
    tabs:[
      // {
      //   key:'Save',
      //   label:'saves',
      // },
      {
        key: 'Info',
        label:i18n.t("string.info"),
      },
      // {
      //   key:'Activity',
      //   label:i18n.t("model.name.activity"),
      // },
      // {
      //   key:'Performance',
      //   label:i18n.t("model.name.performance"),
      // },
    ],
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text:  i18n.t("model.prop.status"),
          value: 'isActive',
        },
        {
          text:  'Mochi Id',
          value: 'mochiId',
        },
        {
          text:  'Password',
          value: 'password',
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'string',
        key:'name',
        name:i18n.t("model.prop.name"),
      },
      {
        type: 'radio',
        key: 'isActive',
        name: 'Is Active',
      },
    ],
    rules:{
      name: [v => !!v || i18n.t("rules.name")],
    },
  },
  edit:true,
  delete:true,
}
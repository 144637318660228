import datasetGender from '@/datasets/gender'
import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text:  i18n.t("model.prop.gender"),
          value: 'gender',
        },
        {
          text:  i18n.t("model.prop.status"),
          value: 'isActive',
          filterable: false,
        },
      ],
    },
  },
  read:{
    tabs:[
      // {
      //   key:'Save',
      //   label:'saves',
      // },
      {
        key: 'Info',
        label:i18n.t("string.info"),
      },
      {
        key:'Activity',
        label:i18n.t("model.name.activity"),
      },
      {
        key:'Performance',
        label:i18n.t("model.name.performance"),
      },
    ],
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text:  i18n.t("model.prop.gender"),
          value: 'gender',
        },
        {
          text:  i18n.t("model.prop.code"),
          value: 'code',
        },
        {
          text:  i18n.t("model.prop.status"),
          value: 'isActive',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'string',
        key:'name',
        name:i18n.t("model.prop.name"),
      },
      {
        type: 'select',
        model: 'gender',
        defaultValue: '',
        key:'gender',
        name:i18n.t("model.prop.gender"),
        options: datasetGender,
        optionText: 'name',
        optionValue: 'value',
      },
    ],
    rules:{
      name: [v => !!v || i18n.t("rules.name")],
    },
  },
  edit:true,
  delete:true,
}
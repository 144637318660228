import Admin from '@/models/bread/Admin/prizeType'

export default {
  key:'PrizeType',
  name:{
    singular:'Prize Type',
    plural:'Prize Types',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
import moment from 'moment'

export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'School',
          value: 'School',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Game',
          value: 'Game',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Syllabus',
          value: 'Syllabus',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Classrooms',
          value: 'countClassroomOnEvent',
        },
        {
          text: 'Start',
          value: 'dateStart',
          filterable: false,
        },
        {
          text: 'End',
          value: 'dateEnd',
          filterable: false,
        },
        {
          text: 'Status',
          value: 'eventStatus',
          filterable: false,
        },
      ],
    },
  },
  read:{
    tabs:[
      // {
      //   key:'Participant',
      //   label:'participants',
      // },
      // {
      //   key:'Checkpoint',
      //   label:'checkpoints',
      // },
      // {
      //   key:'Prize',
      //   label:'Prizes',
      // },
      // {
      //   key:'Submission',
      //   label:'submissions',
      // },
      // {
      //   key:'Classroom',
      //   label:'classrooms',
      // },
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Status',
          value: 'eventStatus',
          filterable: false,
        },
        {
          text: 'School',
          value: 'School',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Game',
          value: 'Game',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Syllabus',
          value: 'Syllabus',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Start',
          value: 'timestampStart',
          filterable: false,
        },
        {
          text: 'End',
          value: 'timestampEnd',
          filterable: false,
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'model',
        key:'schoolId',
        name:'School',
        model:'school',
        defaultValue:1,
      },
      {
        type:'model',
        key:'gameId',
        name:'Game',
        model:'game',
        defaultValue:1,
      },
      {
        type:'model',
        key:'syllabusId',
        name:'Syllabus',
        model:'syllabus',
        defaultValue:1,
      },
      {
        type:'string',
        key:'name',
        name:'Name',
      },
      {
        type:'string',
        key:'timestampStart',
        name:'Timestamp Start',
        defaultValue:moment().hour(8).minute(0).second(0).utc().format(),
      },
      {
        type:'string',
        key:'timestampEnd',
        name:'Timestamp End',
        defaultValue:moment().hour(23).minute(0).second(0).add(5,'days').utc().format(),
      },
    ],
    rules:{
      schoolId: [v => !!v || 'School is required'],
      name: [v => !!v || 'Name is required'],
    },
  },
  edit:{
    fields:[
      {
        type:'model',
        key:'schoolId',
        name:'School',
        model:'school',
        defaultValue:1,
      },
      {
        type:'model',
        key:'gameId',
        name:'Game',
        model:'game',
        defaultValue:1,
      },
      {
        type:'model',
        key:'syllabusId',
        name:'Syllabus',
        model:'syllabus',
        defaultValue:1,
      },
      {
        type:'string',
        key:'name',
        name:'Name',
      },
      {
        type:'string',
        key:'timestampStart',
        name:'Timestamp Start',
      },
      {
        type:'string',
        key:'timestampEnd',
        name:'Timestamp End',
      },
    ],
    rules:{
      schoolId: [v => !!v || 'School is required'],
      name: [v => !!v || 'Name is required'],
    },
  },
  delete:true,
}
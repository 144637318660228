import Moderator from '@/models/bread/Moderator/eventTopic'

export default {
    key: 'EventTopic',
    name: {
        singular: 'Topic',
        plural: 'Topics',
    },
    parents: [
        'SchoolYear',
    ],
    grandParents:[
      'Event',
    ],
    Admin: false,
    Moderator: Moderator,
    Teacher: false,
}
export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'School',
          value: 'School',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Classroom',
          value: 'Classroom',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Registration',
          value: 'registration',
        },
        {
          text: 'Transcript',
          value: 'Transcript',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Status',
          value: 'isActive',
          filterable: false,
        },
      ],
    },
  },
  read:{
    tabs:[
      // {
      //   key:'Save',
      //   label:'saves',
      // },
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Classroom',
          value: 'Classroom',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Avatar',
          value: 'avatar',
        },
        {
          text: 'Status',
          value: 'isActive',
          filterable: false,
        },
        {
          text: 'Activated',
          value: 'timestampActivated',
          filterable: false,
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'model',
        key:'schoolId',
        name:'School',
        model:'school',
      },
      {
        type:'model',
        key:'classroomId',
        name:'Classroom',
        model:'classroom',
      },
      {
        type:'string',
        key:'name',
        name:'Name',
      },
    ],
    rules:{
      classroomId: [v => !!v || 'Classroom is required'],
      name: [v => !!v || 'Name is required'],
    },
  },
  edit:true,
  delete:true,
}
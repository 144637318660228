import datasetSchoolYear from '@/datasets/schoolYear'
import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.name.student"),
          value: 'countStudent',
        },
        {
          text: 'Institution',
          value: 'Institution.name',
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'Student',
        label: i18n.t("model.name.students"),
      },
      // {
      //   key:'Teacher',
      //   label:'teachers',
      // },
      // {
      //   key: 'EventOnClassroom',
      //   label: i18n.t("model.name.events"),
      // },
      {
        key: 'Info',
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        // {
        //   text: 'Invitation',
        //   value: 'classroomMessageInvitation',
        // },
        {
          text:  i18n.t("string.self_registration"),
          value: 'isEnable',
          filterable: false,
        },
        {
          text: i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [{
        type: 'select',
        key: 'schoolYear',
        name: i18n.t("model.prop.schoolYear"),
        options: datasetSchoolYear,
        optionText: 'name',
        optionValue: 'id',
        defaultValue: 1,
      },
      {
        type:'model',
        key:'syllabusKey',
        name:i18n.t("model.name.syllabus"),
        model:'syllabus',
        optionValue: 'key',
        defaultValue:'s010',
      },
      {
        type: 'string',
        key: 'name',
        name: i18n.t("model.prop.name"),
      },
      {
        type: 'radio',
        key: 'enableWalkin',
        name: i18n.t("view.PageBackendClassroom.self_registration"),
      },
      {
        type: 'radio',
        key: 'isExpress',
        name: i18n.t("string.express_classroom"),
      },
    ],
    rules: {
      name: [v => !!v || i18n.t("rules.name")],
    },
  },
  edit: true,
  delete: true,
}
import Moderator from '@/models/bread/Moderator/dailyGameProgress'

export default {
  key:'DailyGameProgress',
  name:{
    singular:'DailyGameProgress',
    plural:'DailyGameProgress',
  },
  parents:[],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
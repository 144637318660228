
import Moderator from '@/models/bread/Moderator/playerStudent'


export default {
  key:'Student',
  name:{
    singular:'Student',
    plural:'Students',
  },
  parents:[
    'Player',
  ],
  grandParents:[
    'Account',
    'Institution'
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
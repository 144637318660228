export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Teachers',
          value: 'countTeacher',
        },
        {
          text: 'Classrooms',
          value: 'countClassroom',
        },
        {
          text: 'Events',
          value: 'countEvent',
        },
        {
          text: 'Status',
          value: 'isActive',
        },
      ],
    },
  },
  read:{
    tabs:[
      // {
      //   key:'Classroom',
      //   label:'classrooms',
      // },
      // {
      //   key:'Teacher',
      //   label:'teachers',
      // },
      // {
      //   key:'Student',
      //   label:'students',
      // },
      // {
      //   key:'Event',
      //   label:'Events',
      // },
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Status',
          value: 'isActive',
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'string',
        key:'name',
        name:'Name',
      },
    ],
    rules:{
      name: [v => !!v || 'Name is required'],
    },
  },
  edit:true,
  delete:true,
}

import Moderator from '@/models/bread/Moderator/galleryWorkshopDrawing'


export default {
  key:'WorkshopDrawing',
  name:{
    singular:'WorkshopDrawing',
    plural:'WorkshopDrawings',
  },
  parents:[
    'Theme',
  ],
  grandParents:[],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
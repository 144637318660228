
import Moderator from '@/models/bread/Moderator/institutionStudentInactive'
export default {
  key:'Student',
  name:{
    singular:'Student',
    plural:'Students',
  },
  parents:[
    'Classroom',
  ],
  grandParents:[
    'Account',
    'Institution'
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
import Admin from '@/models/bread/Admin/game'

export default {
  key:'Game',
  name:{
    singular:'Game',
    plural:'Games',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
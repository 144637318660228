
import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Text',
          value: 'textEventRule',
        },
      ],
    },
  },
  read: {
    tabs: [

      {
        key: 'Info',
        label:  i18n.t("string.info"),
      },

    ],
    table: {
      headers: [
        {
          text: 'Text',
          value: 'textEventRule',
        },
        {
          text: 'Lang',
          value: 'lang',
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: 'text',
        key: 'text',
        name: 'Text',
      },

    ],
    rules: {
      text: [v => !!v || 'Text is required'],
    },
  },
  edit: true,
  delete: true,
}
export default {
  key:'Prize',
  name:{
    singular:'Prize',
    plural:'Prizes',
  },
  parents:[
    //
  ],
  Admin:false,
  Moderator:false,
  Teacher:false,
}
import Admin from '@/models/bread/Admin/achievement'

export default {
  key:'Achievement',
  name:{
    singular:'Achievement',
    plural:'Achievements',
  },
  parents:[
    'Game',
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
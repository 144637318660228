
export default {
  browse: {
    table: {
      headers: [
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Institution',
          value: 'Institution',
        },
        {
          text: 'Classroom',
          value: 'Classroom',
        },
      ],
    },
  },
  read: false,
  add: false,
  edit: false,
  delete: false,
}
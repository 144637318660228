import Moderator from '@/models/bread/Moderator/news'

export default {
  key:'News',
  server:'game',
  name:{
    singular:'News',
    plural:'News',
  },
  parents:[],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
import Admin from '@/models/bread/Admin/eventPrize'
import Moderator from '@/models/bread/Moderator/eventPrize'
import Teacher from '@/models/bread/Teacher/eventPrize'

export default {
  key: 'EventPrize',
  name: {
    singular: 'Event Prize',
    plural: 'Event Prizes',
  },
  parents: [
    'Event',
    'GamePrize',
  ],
  Admin: Admin,
  Moderator: Moderator,
  Teacher: Teacher,
}
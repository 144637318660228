
import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Mochi Id',
          value: 'mochiId',
        },
        {
          text: 'Award',
          value: 'isSendAward',
        },
        {
          text: 'Dawing',
          value: 'drawing',
        },
        {
          text: '',
          value: 'sendAward',
        },
      ],
    },
  },
  read: false,
  add:false,
  edit:true,
  delete:true,
}
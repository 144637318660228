import Admin from '@/models/bread/Admin/dummy'

export default {
  key:'Dummy',
  name:{
    singular:'Dummy',
    plural:'Dummies',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
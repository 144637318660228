import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        // {
        //   text: 'No.',
        //   value: 'index',
        //   sortable: false,
        //   filterable: false,
        // },
        {
          text: i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        // {
        //   text: 'Model',
        //   value: 'model',
        // },
        // {
        //   text: 'Model ID',
        //   value: 'modelId',
        // },
        {
          text: i18n.t("model.prop.action"),
          value: 'action',
        },
        {
          text: i18n.t("model.prop.description"),
          value: 'text',
        },
        // {
        //   text: 'Url',
        //   value: 'url',
        // },
        {
          text:i18n.t("model.prop.data"),
          value:'data-table-expand',
        },
      ],
    },
  },
  read:false,
  add:false,
  edit:false,
  delete:false,
}
import i18n from '@/plugins/i18n'
export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("model.name.school"),
          value: 'School',
        },
        {
          value: 'delete',
          sortable: false,
          filterable: false,
        },
      ],
    },
  },

  add: {
    fields: [{
      type: 'model',
      key: 'schoolId',
      name: i18n.t("model.name.school"),
      model: 'school',

    }, ],
    rules: {
      schoolId: [v => !!v || i18n.t("rules.school"),],
    },
  },
  edit: false,
  delete: true,
  read: false,
}
import Admin from '@/models/bread/Admin/achievementReward'

export default {
  key:'AchievementReward',
  name:{
    singular:'Achievement Reward',
    plural:'Achievement Rewards',
  },
  parents:[
    'Achievement',
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
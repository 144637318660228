import Admin from '@/models/bread/Admin/module'

export default {
  key:'Modules',
  name:{
    singular:'Module',
    plural:'Modules',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
import Admin from '@/models/bread/Admin/event'
import Moderator from '@/models/bread/Moderator/event'
import Teacher from '@/models/bread/Teacher/event'

export default {
    key: 'Event',
    name: {
        singular: 'Event',
        plural: 'Events',
    },
    parents: [
        'School',
        'Game',
    ],
    Admin: Admin,
    Moderator: Moderator,
    Teacher: Teacher,
}
import Admin from '@/models/bread/Admin/prizeTypeReward'

export default {
  key:'PrizeTypeReward',
  name:{
    singular:'Prize Type Reward',
    plural:'Prize Type Rewards',
  },
  parents:[
    'PrizeType',
    'Item',
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
 
  import i18n from '@/plugins/i18n'
  export default {
    browse: {
      table: {
        headers: [{
            text: i18n.t("model.prop.no"),
            value: 'index',
            sortable: false,
          },
          {
            text: i18n.t("model.prop.name"),
            value: 'name',
          },
          {
            text: i18n.t("model.prop.code"),
            value: 'code',
          },
          {
            text: i18n.t("model.name.teachers"),
            value: 'countTeacher',
          },
          {
            text: i18n.t("model.name.classrooms"),
            value: 'countClassroom',
          },
          {
            text: i18n.t("model.name.events"),
            value: 'countEvent',
          },
          {
            text: '',
            value: 'schoolSelect',
          },
        ],
      },
    },
  read:false,
  add:false,
  edit:false,
  delete:false,
}

import Admin from '@/models/bread/Admin/transcript'

export default {
  key:'Transcript',
  name:{
    singular:'Transcript',
    plural:'Transcripts',
  },
  parents:[
    'School',
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}

import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.name.series"),
          value: 'Series.name',
        },
        {
          text: i18n.t("model.prop.start"),
          value: 'timestampStart',
          filterable: false,
        },
        {
          text: i18n.t("model.prop.end"),
          value: 'timestampEnd',
          filterable: false,
        },
        {
          text: i18n.t("model.prop.status"),
          value: 'eventStatus',
          filterable: false,
        },
        {
          text: '',
          value: 'institutionScoreboard',
          filterable: false,
        },
      ],
    },
  },
  read: false,
  add: false,
  edit: false,
  delete: true,
}
import Admin from '@/models/bread/Admin/seed'

export default {
  key:'Seed',
  name:{
    singular:'Seed',
    plural:'Seeds',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
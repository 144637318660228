import Admin from '@/models/bread/Admin/shopItem'

export default {
  key:'Shop Items',
  name:{
    singular:'Shop Item',
    plural:'Shop Items',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
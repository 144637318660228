
import Moderator from '@/models/bread/Moderator/page'


export default {
  key:'Page',
  name:{
    singular:'Page',
    plural:'Pages',
  },
  parents:[
    
  ],
  grandParents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
import Moderator from '@/models/bread/Moderator/accountGroup'

export default {
  key:'Group',
  name:{
    singular:'Group',
    plural:'Groups',
  },
  parents:[
    'Account',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
import Admin from '@/models/bread/Admin/achievementProgress'

export default {
  key:'AchievementProgress',
  name:{
    singular:'Achievement Progress',
    plural:'Achievement Progress',
  },
  parents:[
    'Achievement',
    'Student',
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
import Moderator from '@/models/bread/Moderator/sponsor'

export default {
  key:'Sponsor',
  server:'event',
  name:{
    singular:'Sponsor',
    plural:'Sponsors',
  },
  parents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
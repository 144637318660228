import Admin from '@/models/bread/Admin/gamePrize'
// import Moderator from '@/models/bread/Moderator/gamePrize'

export default {
    key: 'GamePrize',
    name: {
        singular: 'Game Prize',
        plural: 'Game Prizes',
    },
    parents: [
        'Game',
        'PrizeType',
    ],
    Admin: Admin,
    Moderator: false,
    Teacher: false,
}

import Moderator from '@/models/bread/Moderator/playerInventory'


export default {
  key:'Inventory',
  name:{
    singular:'Inventory',
    plural:'Inventories',
  },
  parents:[
    'Player',
  ],
  grandParents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}
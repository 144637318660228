export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Image',
          value: 'imageUrl',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'URL',
          value: 'url',
        },
        {
          text: 'Events',
          value: 'countEvent',
        },
        {
          text: 'Published',
          value: 'isPublished',
        },
      ],
    },
  },
  read:{
    tabs:[
      // {
      //   key:'Event',
      //   label:'events',
      // },
      // {
      //   key:'Save',
      //   label:'saves',
      // },
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'URL',
          value: 'url',
        },
        {
          text: 'Published',
          value: 'isPublished',
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:false,
  edit:false,
  delete:false,
}
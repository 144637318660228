
import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.prop.subtitle"),
          value: 'subtitle',
        },
        {
          text: i18n.t("model.name.events"),
          value: 'Event.length',
        },
        
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'EventOnFestival',
        label: i18n.t("model.name.events"),
      },
       {
        key: 'FestivalSchool',
        label: i18n.t("model.name.schools"),
      },
      {
        key: 'FestivalPrize',
        label: i18n.t("model.name.prizes"),
      },
      {
        key: 'FestivalSponsor',
        label: i18n.t("model.name.sponsors"),
      },
      {
        key: 'Info',
        label:  i18n.t("string.info"),
      },

    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.prop.subtitle"),
          value: 'subtitle',
        },
        {
          text: i18n.t("model.prop.code"),
          value: 'code',
        },
        {
          text:  i18n.t("view.PageBackendEvent.notice"),
          value: 'festivalMessageNotice',
        },
        {
          text:  i18n.t("view.PageBackendEvent.invitation"),
          value: 'festivalMessageInvitation',
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: 'string',
        key: 'name',
        name: 'Name',
      },
      {
        type: 'string',
        key: 'subtitle',
        name: 'Subtitle',
      },
     
    ],
    rules: {
      name: [v => !!v || 'Name is required'],
      subtitle: [v => !!v || 'Subtitle is required'],
    },
  },
  edit: {
    fields: [
      {
        type: 'string',
        key: 'name',
        name: 'Name',
      }, 
      {
        type: 'string',
        key: 'subtitle',
        name: 'Subtitle',
      },
      {
        type: 'string',
        key: 'code',
        name: 'Code',
      },
    ],
    rules: {
      name: [v => !!v || 'Name is required'],
      subtitle: [v => !!v || 'Subtitle is required'],
      code: [v => !!v || 'Code is required'],
    },
  },
  delete: true,
}
import Admin from '@/models/bread/Admin/student'
import Moderator from '@/models/bread/Moderator/student'
import Teacher from '@/models/bread/Teacher/student'

export default {
  key:'Student',
  name:{
    singular:'Student',
    plural:'Students',
  },
  parents:[
  ],
  grandParents:[
  ],
  Admin:Admin,
  Moderator:Moderator,
  Teacher:Teacher,
}
import i18n from "@/plugins/i18n";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text: "Mochi Id",
          value: "mochiId",
        },
        {
          text: "Count",
          value: "_count",
        },
      ],
    },
  },
  read: false,
  add: false,
  edit: false,
  delete: false,
};

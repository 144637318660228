import Admin from '@/models/bread/Admin/winner'

export default {
  key:'Winner',
  name:{
    singular:'Winner',
    plural:'Winners',
  },
  parents:[
    'GamePrize',
    'Participant',
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}
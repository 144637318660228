export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Images',
          value: 'images',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Category',
          value: 'category',
        },
        {
          text: 'Key',
          value: 'key',
        },
        {
          text: 'Name',
          value: 'translatableName',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Sort',
          value: 'sort',
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Name',
          value: 'translatableName',
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:false,
  edit:false,
  delete:false,
}

import Moderator from '@/models/bread/Moderator/application'

export default {
  key:'Application',
  name:{
    singular:'Application',
    plural:'Applications',
  },
  parents:[
    //
  ],
  Moderator:Moderator,
}
export default [
  {
    id:1,
    name:'Not specific',
    value:null
  },
  {
    id:2,
    name:'Male',
    value:'Male'
  },
  {
    id:3,
    name:'Female',
    value:'Female'
  },
]